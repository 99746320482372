import React, { useEffect, useMemo, useRef, useState } from "react"
import {
	GlobalSidebar,
	SidebarList,
	FormSwitchInput,
	FormInputAntd,
	FormSelectAntd,
	AntdButton,
	AntdInput,
} from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { SDSuppliersInterface } from "@type/staticData.types"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { SDSuppliersInitialValues, SDSuppliersSchema } from "Schema/StaticData/SDSuppliers"
import {
	filteredSDSupplierListBySearch,
	getSDSuppliers,
	isSDSuppliersLoading,
	isSDSuppliersSaving,
	saveSDSuppliers,
	selectSDSuppliersById,
} from "store/StaticData/SDSuppliers.slice"
import { useAppDispatch, useAppSelector } from "hooks"
import { selectSDCompaniesDropdownList } from "store/StaticData/SDCompanies.slice"
import { selectCurrentUser } from "store/auth.slice"
import { SearchOutlined } from "@ant-design/icons"
import { Divider } from "antd-v5"

interface EquipmentTypeFormHandler extends SDSuppliersInterface {}

const SDSuppliers = () => {
	const [selectedSDSuppliers, setSelectedSDSuppliers] = useState<string | null>("")
	const [search, setSearch] = useState<string>("")

	const dispatch = useAppDispatch()
	const saving = useAppSelector(isSDSuppliersSaving())
	const loading = useAppSelector(isSDSuppliersLoading())
	const SDSuppliers = useAppSelector(selectSDSuppliersById(selectedSDSuppliers))
	const allCompanies = useAppSelector(selectSDCompaniesDropdownList) || []
	const filteredSDSupplierSearch = useAppSelector(filteredSDSupplierListBySearch(search, ["name"]))
	const isNewBeingCreated = useRef(false)
	const user = useAppSelector(selectCurrentUser())
	const methods = useForm<EquipmentTypeFormHandler>({ resolver: yupResolver(SDSuppliersSchema) })
	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { errors, isSubmitted, isSubmitting },
	} = methods

	const sidebarSDSuppliers = useMemo(() => {
		return filteredSDSupplierSearch?.map(SDSuppliers => ({
			_id: SDSuppliers._id,
			name: SDSuppliers.name,
		}))
	}, [saving, filteredSDSupplierSearch?.length, isSubmitted, search])

	const onSubmit: SubmitHandler<EquipmentTypeFormHandler> = data => {
		let organization
		isNewBeingCreated.current = true
		if (user?.roles.filter(role => role.organization).length === 1) {
			organization = user?.roles[0]?.organization ?? ""
		} else {
			organization = data.organization
		}

		dispatch(
			saveSDSuppliers(data, organization, id => {
				setSelectedSDSuppliers(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getSDSuppliers(true, setSelectedSDSuppliers))
	}, [])

	useEffect(() => {
		if (selectedSDSuppliers !== (null && undefined)) {
			reset({ ...SDSuppliersInitialValues, ...SDSuppliers })
		} else if (selectedSDSuppliers === null && !isNewBeingCreated.current) {
			reset({
				...SDSuppliersInitialValues,
				...(user?.roles.length
					? { organization: user.roles[0]?.organization ?? allCompanies?.[1]?.value }
					: {}),
			})
		}
	}, [selectedSDSuppliers])

	const handleSelected = (SDSuppliersId: string | null): void => setSelectedSDSuppliers(SDSuppliersId)

	return (
		<>
			<GlobalSidebar>
				<AntdInput
					onChange={e => setSearch(e.target.value)}
					placeholder="Search"
					type="text"
					suffix={<SearchOutlined size={14} style={{ color: "rgb(156 163 175)" }} />}
				/>
				<Divider className="bg-slate-700" style={{ margin: "15px 0 10px 0" }} />
				<SidebarList
					title={"Suppliers"}
					loading={loading}
					selected={selectedSDSuppliers}
					setSelected={handleSelected}
					list={sidebarSDSuppliers}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-col gap-2">
						<div className="grid grid-cols-4 gap-x-3">
							{user?.roles.some(role => role.isSuperAdmin) || user?.isSuperAdmin ? (
								<FormSelectAntd
									className="col-span-1"
									disabled={!!selectedSDSuppliers}
									value={selectedSDSuppliers ? `${SDSuppliers?.organization}` : watch("organization")}
									label="Company"
									name="organization"
									options={allCompanies ?? []}
								/>
							) : (
								<></>
							)}
							<FormInputAntd className="col-span-1" type="text" label="Supplier Name" name="name" />
						</div>
						<FormSwitchInput
							containerClass="flex  items-center w-40"
							label="Enabled"
							name="enabled"
							watch={watch}
							errors={errors}
							setValue={setValue}
						/>

						<div className="flex gap-x-2">
							<AntdButton
								onClick={() => {
									setSelectedSDSuppliers(null)
								}}
							>
								New
							</AntdButton>
							<AntdButton disabled={isSubmitting} loading={saving} htmlType="submit">
								{selectedSDSuppliers ? "Update" : "Save"}
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default SDSuppliers
