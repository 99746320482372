import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import { toast } from "react-toastify"
import { CSVLink } from "react-csv"
import { isEmpty } from "lodash"
import { useCSVReader } from "react-papaparse"
import { useMasterSheet } from "hooks"
import { selectEmployeeList } from "store/Employee/detail.slice"
import { savePayrolls, selectPayrollSaving } from "store/Account/payroll.slice"
import React, { useEffect, useMemo, useState } from "react"
import { AntdButton, Button, Input, SortableTable } from "components"
import { CloudDownloadOutlined, CloudUploadOutlined } from "@ant-design/icons"
import {
	MasterSheetInterface,
	PayrollDataInterface,
	LocationRatesInterface,
	AccountTableDataInterface,
} from "@type/account.types"
import MasterSheetErrorModal from "./MasterSheetErrorModal"

interface Props {
	data: AccountTableDataInterface[]
	selectedMonth: string
}

export interface FormInterface {
	[locationId_employeeId: string]: {
		[locationId: string]: string
		payrollId: string
	}
}

export interface TableDataInterface {
	tempId: string
	_id: string
	name: string
	pin: string | undefined
	employeeID: string | undefined
	rate: number
	location: string | undefined
	locationName: string | undefined
	locationSin: string | undefined
}

export interface CsvReaderStreamInterface {
	data: string[][]
	errors: any
	meta: {
		delimiter: string
		linebreak: string
		aborted: boolean
	}
}

const headers = [
	{ label: "#", key: "tempId" },
	{ label: "Pin", key: "pin" },
	{ label: "Name", key: "name" },
	{ label: "Site Name", key: "locationName" },
	{ label: "Sin", key: "locationSin" },
	{ label: "Rate", key: "rate" },
]

const MasterSheet: React.FC<Props> = ({ data, selectedMonth }) => {
	const dispatch = useAppDispatch()
	const { transformObjectForPayload, generateFormKey } = useMasterSheet()
	const employeeList = useAppSelector(selectEmployeeList())
	const isPayrollSaving = useAppSelector(selectPayrollSaving)
	const [formData, setFormData] = useState<FormInterface>({})
	const { CSVReader } = useCSVReader()
	const [isCsvImportCompleted, setIsCsvImportCompleted] = useState(false)
	const [openMasterSheetErrorModal, setOpenMasterSheetErrorModal] = useState<boolean>(false)
	const [errorRows, setErrorRows] = useState<TableDataInterface[]>([])

	const modalHandler = (open: boolean) => setOpenMasterSheetErrorModal(open)

	useEffect(() => {
		setFormData({})
	}, [selectedMonth])

	useEffect(() => {
		if (isCsvImportCompleted) {
			submitAllRowHandler()
			setIsCsvImportCompleted(false)
		}
	}, [isCsvImportCompleted])

	const tableData: TableDataInterface[] = useMemo(() => {
		return data
			?.map(ele => {
				const employeeFound = employeeList.find(item => item._id === ele._id)

				const locationRatesIds = ele?.locationRates?.length
					? ele?.locationRates?.filter(ele => !isEmpty(ele))?.map(rate => rate.location)
					: []

				const locationHoursIds = ele?.locationHours?.length
					? ele?.locationHours?.map(rate => rate.location)
					: []

				const locationIds = [...new Set([...locationRatesIds, ...locationHoursIds])]

				const locationRates = locationIds.map(id => {
					const foundLocationRate = ele.locationRates?.find(locRate => locRate.location === id)
					if (foundLocationRate)
						return {
							tempId: generateFormKey(employeeFound?._id || "", foundLocationRate.location),
							_id: ele.documentId as string,
							name: `${employeeFound?.firstName} ${employeeFound?.lastName}`,
							pin: employeeFound?.pin,
							employeeID: employeeFound?._id,
							rate: foundLocationRate.rate || 0,
							location: foundLocationRate.location,
							payrollTermLabel: ele.payrollTermLabel,
							locationName: foundLocationRate.locationName,
							locationSin: foundLocationRate.locationSin,
						}
					const foundLocationHours = ele.locationHours?.find(locHours => locHours.location === id)
					return {
						tempId: generateFormKey(employeeFound?._id || "", foundLocationHours?.location || ""),
						_id: ele.documentId as string,
						name: `${employeeFound?.firstName} ${employeeFound?.lastName}`,
						pin: employeeFound?.pin,
						employeeID: employeeFound?._id,
						rate: 0,
						payrollTermLabel: "",
						location: foundLocationHours?.location,
						locationName: foundLocationHours?.locationName,
						locationSin: foundLocationHours?.locationSin,
					}
				})

				return locationRates
			})
			.flat()
	}, [selectedMonth, data])

	const submitRowHandler = (row: MasterSheetInterface) => {
		const foundPayroll = data?.find(ele => ele.documentId === row._id)
		const formKey = generateFormKey(row.employeeID || "", row.location)
		if (foundPayroll?.documentId) {
			const locationRatesIds = foundPayroll?.locationRates?.length
				? foundPayroll?.locationRates?.filter(ele => !isEmpty(ele))?.map(loc => loc.location)
				: []
			const locationHoursIds = foundPayroll?.locationHours?.length
				? foundPayroll?.locationHours?.map(loc => loc.location)
				: []

			const locationIds = [...new Set([...locationRatesIds, ...locationHoursIds])]

			const locationRates = locationIds.map(id => {
				const foundLocationRate = foundPayroll.locationRates?.find(locRate => locRate.location === id)
				if (foundLocationRate) return { ...foundLocationRate }
				const foundLocationHours = foundPayroll.locationHours?.find(locHours => locHours.location === id)

				return {
					location: foundLocationHours?.location || "",
					locationName: foundLocationHours?.locationName,
					locationSin: foundLocationHours?.locationSin,
					rate: 0,
					additionalHours: 0,
				}
			})

			const updatedRates = locationRates?.map(rate => {
				if (formData?.[formKey]?.[rate?.location]) {
					return { ...rate, rate: Number(formData[formKey][rate?.location]) }
				}
				return rate
			})

			const updatedObject = {
				_id: foundPayroll?.documentId,
				employee: foundPayroll?._id,
				hours: foundPayroll?.hours || 0,
				hoursWorked: foundPayroll?.hoursWorked,
				locationRates: updatedRates,
				month: selectedMonth,
			}

			dispatch(
				savePayrolls([updatedObject], true, () => {
					setFormData(prev => {
						delete prev[formKey]
						return { ...prev }
					})
				}),
			)
		} else {
			const foundEmployee = data.find(ele => ele._id === row.employeeID)
			const locationRates = foundEmployee?.locationHours?.map(locationHour => {
				return {
					location: locationHour?.location || "",
					locationName: locationHour?.locationName,
					locationSin: locationHour?.locationSin,
					rate: 0,
					additionalHours: 0,
				}
			})

			const updatedRates = locationRates?.map(rate => {
				if (formData?.[formKey]?.[rate?.location]) {
					return { ...rate, rate: Number(formData?.[formKey]?.[rate?.location]) }
				}
				return rate
			})

			const updatedObject = {
				employee: foundEmployee?._id,
				hours: foundEmployee?.hours || 0,
				hoursWorked: foundEmployee?.hoursWorked,
				locationRates: updatedRates,
				month: selectedMonth,
			}

			dispatch(
				savePayrolls([updatedObject], true, () => {
					setFormData(prev => {
						delete prev[formKey]
						return { ...prev }
					})
				}),
			)
		}
	}

	const submitAllRowHandler = () => {
		const payload: PayrollDataInterface[] = []
		const modifiedData = transformObjectForPayload(formData)

		Object.entries(modifiedData).forEach(([employeeID, formDataArray]) => {
			const payloadItems: PayrollDataInterface = formDataArray.reduce((accForm, currForm) => {
				const { payrollId } = currForm
				const foundPayroll = data.find(payroll => payroll.documentId === payrollId)

				if (foundPayroll?.documentId) {
					const locationRatesIds =
						foundPayroll?.locationRates
							?.filter(({ location: locationId }) => !!locationId)
							?.map(({ location: locationId }) => locationId) || []

					const locationHoursIds = foundPayroll?.locationHours?.map(loc => loc.location) || []

					const locationIds = [...new Set([...locationRatesIds, ...locationHoursIds])]

					const locationRates = locationIds.map(locationId => {
						const foundLocationRate = foundPayroll.locationRates?.find(
							({ location = "" }) => location === locationId,
						)

						const foundLocationHours = foundPayroll.locationHours?.find(
							({ location = "" }) => location === locationId,
						)

						if (foundLocationRate) return foundLocationRate

						return {
							location: foundLocationHours?.location || "",
							locationName: foundLocationHours?.locationName,
							locationSin: foundLocationHours?.locationSin,
							rate: 0,
							additionalHours: 0,
						}
					})

					const updatedRates = (accForm?.locationRates ? accForm?.locationRates : locationRates)?.map(
						(rate: LocationRatesInterface) => {
							if (currForm?.[rate?.location]) {
								return { ...rate, rate: Number(currForm?.[rate?.location]) }
							}
							return rate
						},
					)

					return {
						...accForm,
						_id: foundPayroll?.documentId,
						employee: foundPayroll?._id,
						hours: foundPayroll?.hours || 0,
						hoursWorked: foundPayroll?.hoursWorked,
						locationRates: updatedRates,
						month: selectedMonth,
					}
				} else {
					const foundEmployee = data.find(ele => ele._id === employeeID)
					const locationRates = foundEmployee?.locationHours?.map(locationHour => {
						return {
							location: locationHour?.location || "",
							locationName: locationHour?.locationName,
							locationSin: locationHour?.locationSin,
							rate: 0,
							additionalHours: 0,
						}
					})

					const updatedRates = (accForm?.locationRates ? accForm?.locationRates : locationRates)?.map(
						(rate: LocationRatesInterface) => {
							if (currForm?.[rate?.location]) {
								return { ...rate, rate: Number(currForm?.[rate?.location]) }
							}
							return rate
						},
					)

					return {
						...accForm,
						employee: foundEmployee?._id,
						hours: foundEmployee?.hours || 0,
						hoursWorked: foundEmployee?.hoursWorked,
						locationRates: updatedRates,
						month: selectedMonth,
					}
				}
			}, {} as any)

			payload.push(payloadItems)
		})

		dispatch(
			savePayrolls(payload, true, () => {
				setFormData({})
			}),
		)
	}

	const onCsvUploadAccepetedHandler = (results: CsvReaderStreamInterface) => {
		const csvData: string[][] = results.data
		const masterSheetCsvMap = new Map<string, Partial<TableDataInterface>>()

		const rows = csvData.filter((_, i) => i !== 0)
		rows.forEach(record => {
			const temp: Partial<TableDataInterface> = {
				tempId: record[0],
				pin: record[1],
				name: record[2],
				locationName: record[3],
				locationSin: record[4],
				rate: parseFloat(record[5]),
			}
			masterSheetCsvMap.set(record[0], temp)
		})

		const tempRows: TableDataInterface[] = []

		tableData.forEach(row => {
			const { tempId, location, _id } = row
			const isRecordExist = masterSheetCsvMap.has(tempId)

			if (isRecordExist) {
				const csvRow = masterSheetCsvMap.get(tempId)

				setFormData((prev: any) => ({
					...prev,
					[tempId]: {
						[location || ""]: csvRow?.rate,
						payrollId: _id || "",
					},
				}))
				setIsCsvImportCompleted(true)
			} else {
				tempRows.push(row)
			}
		})
		if (tempRows.length) {
			setFormData({})
			toast.error("Invalid Csv file")
			setOpenMasterSheetErrorModal(true)
			setErrorRows(tempRows)
		}
	}

	return (
		<div>
			<div className="h-[80vh] w-full ">
				<div className="fixed top-[73px] right-10 flex gap-2">
					<CSVReader onUploadAccepted={onCsvUploadAccepetedHandler}>
						{({ getRootProps }: any) => (
							<AntdButton
								{...getRootProps()}
								className="ml-auto !py-1"
								disabled={!tableData.length}
								icon={<CloudUploadOutlined />}
							>
								Import CSV
							</AntdButton>
						)}
					</CSVReader>

					<CSVLink data={tableData} headers={headers} filename="Master sheet">
						<AntdButton
							className="flex items-center justify-center"
							icon={<CloudDownloadOutlined />}
							disabled={!tableData.length}
						>
							Export CSV
						</AntdButton>
					</CSVLink>

					<Button
						disabled={!Object.keys(formData).length}
						className="ml-auto !py-1"
						onClick={submitAllRowHandler}
						loading={isPayrollSaving}
					>
						Update All
					</Button>
				</div>

				<div>
					<SortableTable
						rowClassName="odd:bg-white even:bg-slate-50 h-fit py-0"
						columns={[
							{ label: "Pin", key: "pin", className: "text-center pl-5" },
							{ label: "Name", key: "name", className: "text-left pl-20" },
							{ label: "Status", key: "payrollTermLabel", className: "text-left pl-20" },
							{ label: "Site Name", key: "locationName", className: "text-left pl-20" },
							{ label: "Sin", key: "locationSin", className: "text-left pl-16" },
							{
								label: "Rate",
								key: "rate",
								className: "text-center",
								render: ({ row, key }) => {
									const { _id = "", location, employeeID } = row
									const formKey = generateFormKey(employeeID, location)

									const value = formData[formKey]?.[location] || row[key] || ""

									return (
										<Input
											className="w-16 px-2 py-0.375 text-sm"
											value={value}
											inputMode="numeric"
											onChange={e => {
												const newFormKey = generateFormKey(employeeID, location)

												if (e) {
													setFormData((prev: any) => ({
														...prev,
														[newFormKey]: {
															[location]: e.target.value,
															payrollId: _id || "",
														},
													}))
												}
											}}
										/>
									)
								},
							},
							{
								label: "Action",
								key: "",
								className: "text-center",
								render: ({ row }) => {
									const { employeeID, location } = row
									const formKey = generateFormKey(employeeID, location)

									return (
										<div className="flex justify-start">
											<Button
												className="py-1 px-4 text-2 text-white"
												disabled={!formData[formKey]}
												onClick={() => submitRowHandler(row)}
											>
												Update
											</Button>
										</div>
									)
								},
							},
						]}
						rows={tableData}
					/>
				</div>
			</div>
			<MasterSheetErrorModal open={openMasterSheetErrorModal} modalHandler={modalHandler} rows={errorRows} />
		</div>
	)
}

export default MasterSheet
