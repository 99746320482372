import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { checkIfTextExists, sortByProperty, updateState } from "config/utils"
import { BranchesInterface, GetBranchesInterface } from "@type/branches.types"
import { AppThunk, RootState } from "store"
import { branchService } from "services"

interface BranchStateInterface {
	list: Array<GetBranchesInterface>
	loading: boolean
	saving: boolean
	newBranch: null | GetBranchesInterface
}

const initialState: BranchStateInterface = {
	list: [],
	loading: false,
	saving: false,
	newBranch: null,
}

const branchSlice = createSlice({
	name: "branch",
	initialState,
	reducers: {
		requestBranch: branch => {
			branch.loading = true
		},
		getBranchesRequestSuccess: (branch, action) => {
			branch.list = action.payload
			branch.loading = false
		},
		getBranchesRequestFailed: branch => {
			branch.loading = false
		},
		requestSavingBranchData: branchData => {
			branchData.saving = true
		},
		SavingBranchSuccess: (branchData, action: PayloadAction<BranchesInterface>) => {
			branchData.saving = false

			const [UpdatedValue] = updateState(branchData.list, action.payload, "_id")

			branchData.newBranch = action.payload
			branchData.list = UpdatedValue
		},
		ClearBranch: branch => {
			branch.newBranch = null
		},
		ClearBranchState: state => {
			state.list = []
			state.loading = false
			state.saving = false
			state.newBranch = null
		},
		SavingBranchSuccessFails: branch => {
			branch.saving = false
		},
	},
})

const getBranches =
	(fetchAll?: boolean): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestBranch())
			const { data: branchResponse } = await branchService.getBranches(fetchAll ?? true)
			const sortedBranch = branchResponse.sort(sortByProperty("name"))
			dispatch(getBranchesRequestSuccess(sortedBranch))
		} catch (error) {
			dispatch(getBranchesRequestFailed)
		}
	}

const saveBranch =
	(branchData: BranchesInterface, organization: string): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingBranchData())
			if (branchData._id) {
				data = await branchService.updateBranch(branchData._id, branchData)
				data["data"]["organization"] = organization
			} else {
				data = await branchService.createBranch(branchData, organization)
			}
			const { data: branchResponse } = data
			dispatch(SavingBranchSuccess(branchResponse))
		} catch (error) {
			dispatch(SavingBranchSuccessFails())
		}
	}

export { getBranches, saveBranch }
//REDUCER
export default branchSlice.reducer

//ACTIONS
export const {
	ClearBranchState,
	ClearBranch,
	requestBranch,
	getBranchesRequestSuccess,
	getBranchesRequestFailed,
	requestSavingBranchData,
	SavingBranchSuccess,
	SavingBranchSuccessFails,
} = branchSlice.actions

//SELECTORS

const selectBranchState = (state: RootState) => state.branch
const selectBranchList = () => (state: RootState) => selectBranchState(state).list
const selectBranchIsLoading = () => (state: RootState) => selectBranchState(state).loading
const selectBranchIsSaving = () => (state: RootState) => selectBranchState(state).saving
const selectNewBranchAdded = () => (state: RootState) => selectBranchState(state).newBranch
const selectBranchById = (branchId: string | null) => (state: RootState) =>
	selectBranchList()(state)?.find(branch => branch._id === branchId)

const filteredListBySearch = (searchOn: string, searchOnFields: string[]) => {
	return createSelector(
		(state: RootState) => state.branch.list,
		branchList =>
			[...checkIfTextExists(branchList, searchOn, searchOnFields)].sort((a: any, b: any) =>
				a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
			),
	)
}

const selectBranchListSorted = () =>
	createSelector(
		(state: RootState) => state.branch.list,
		branchList => {
			return branchList
				? [...branchList]?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
				: []
		},
	)

const selectBranchesMappedAsOptions = () => (state: RootState) =>
	selectBranchList()(state)
		.map(branch => ({ label: branch.name, value: branch._id }))
		.sort(sortByProperty("label"))

export {
	selectNewBranchAdded,
	filteredListBySearch,
	selectBranchListSorted,
	selectBranchList,
	selectBranchState,
	selectBranchById,
	selectBranchIsLoading,
	selectBranchIsSaving,
	selectBranchesMappedAsOptions,
}
