import React from "react"
import { DatePicker } from "antd"
import { RangeValue } from "rc-picker/lib/interface"
import { Moment } from "moment"
import "antd/dist/antd.min.css"
import { RangePickerProps } from "antd/lib/date-picker"

const { RangePicker } = DatePicker

type Props = RangePickerProps & {
	format?: string
	placeholder?: [string, string]
	allowClear?: boolean
	value: RangeValue<Moment>
	onChange: (values: RangeValue<Moment>, formatString: [string, string]) => void
	style?: { [key: string]: string }
}

const DateRangePicker: React.FC<Props> = ({
	allowClear = true,
	placeholder,
	style = { border: "solid slate", height: "48px", minWidth: "280px" },
	format,
	value,
	onChange,
	...rest
}) => {
	return (
		<RangePicker
			style={style}
			size={"large"}
			placeholder={placeholder}
			allowClear={allowClear}
			value={value}
			format={format}
			onChange={onChange}
			{...rest}
		/>
	)
}

export default DateRangePicker
