import { Table2 } from "components/index"
import Modal from "components/Modal"
import React from "react"
import { TableDataInterface } from "./MasterSheet"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	rows: TableDataInterface[]
}

const MasterSheetErrorModal: React.FC<Props> = ({ open, modalHandler, rows }) => {
	const columns = [
		{ label: "Pin", key: "pin", className: "text-left" },
		{ label: "Name", key: "name", className: "text-left" },
		{ label: "Site Name", key: "locationName", className: "text-left" },
		{ label: "Sin", key: "locationSin", className: "text-left" },
		{ label: "Rate", key: "rate", className: "text-left" },
	]
	return (
		<Modal
			open={open}
			modalHandler={modalHandler}
			title={<p className="pt-2.5 pb-4 text-center text-lg font-semibold">MasterSheet Related Issues</p>}
		>
			<div className="w-[800px]">
				<p className="pt-2.5 pb-4 text-center text-sm font-semibold">Following rows have issues</p>
				<Table2 columns={columns} rows={rows} />
			</div>
		</Modal>
	)
}

export default MasterSheetErrorModal
