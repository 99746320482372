import axios from "axios"
import { CreateCustomersInterface, GetCustomersInterface } from "@type/customers.types"

const ROOT_PATH = "customers"
export default class CustomersService {
	static createCustomer = (customer: CreateCustomersInterface, organization: string) =>
		axios.post<GetCustomersInterface>(ROOT_PATH, { customer, organization })

	static getAllCustomers = (showAll: boolean) =>
		axios.get<Array<GetCustomersInterface>>(`${ROOT_PATH}`, { params: { showAll } })

	static getCustomerById = (id: string) => axios.get<GetCustomersInterface>(`${ROOT_PATH}/${id}`)

	static updateCustomer = (id: string, customer: Partial<GetCustomersInterface>) =>
		axios.put<GetCustomersInterface>(`${ROOT_PATH}/${id}`, customer)
}
