import React, { useEffect, useMemo, useState } from "react"
import { GlobalSidebar, SidebarList, Report, Button } from "components"
import { useAppDispatch, useFeatureFlags } from "hooks"
import { getPayGroup } from "store/PayGroups/payGroup.slice"
import { getBranches } from "store/Branches/branch.slice"
import { getAllLocations } from "store/Location/locationDetails.slice"
import {
	reports,
	Report as ReportInterface,
	reportNameToUrl,
	COMMON_FIELDS,
	reportsToMask,
	REPORT_NAMES,
} from "./report-config"
import { caseConverters, convertDateTimeToUTC } from "config/utils"
import { reportService } from "services/"
import { getCustomers } from "store/Customers/customers.slice"
import { getSDSuppliers } from "store/StaticData/SDSuppliers.slice"
import { PDFDocument } from "pdf-lib"
import moment from "moment"
import { DATE_FORMATS, IS_SUPER_ADMIN, Permissions, timeTrailLogoBase64 } from "config/constants"
import PdfViewer from "./PdfViewer"
import { useAbac } from "react-abac"

const FILE_TYPES = {
	pdf: {
		header: "application/pdf",
		fileName: "report.pdf",
	},
	excel: {
		header: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		fileName: "report.xlsx",
	},
}
const valuesToAdd = [{ report: REPORT_NAMES.HOURS_SUMMARY_BY_LOCATION, values: { groupBySupplier: true } }]

const Index = () => {
	const dispatch = useAppDispatch()
	const [loading, setLoading] = useState(false)
	const { userHasPermissions } = useAbac()
	const { featureFlagEnabled, featureFlagsLoading } = useFeatureFlags()
	const isSuperAdmin = userHasPermissions(IS_SUPER_ADMIN)
	const shouldViewPayroll = userHasPermissions(Permissions.VIEW_PAYROLL)
	const [selectedReport, setSelectedReport] = useState<ReportInterface | null>(
		reports.find(item => featureFlagEnabled(item.featureName)) || null,
	)
	const [pdf, setPdf] = useState<Blob | null>(null)
	const [downloadReport, setDownloadReport] = useState<{ fileURL: string; fileName: string } | null>(null)

	const reportsList = useMemo(() => {
		return reports
			.filter(
				item =>
					(item.perform ? userHasPermissions(item.perform) : true) && featureFlagEnabled(item.featureName),
			)
			.map(item => ({ _id: item.name, name: item.title }))
	}, [shouldViewPayroll, featureFlagsLoading, reports])

	useEffect(() => {
		dispatch(getPayGroup())
		dispatch(getBranches(false))
		dispatch(getSDSuppliers(false))
		// dispatch(getEmployees())
		dispatch(getAllLocations())
		dispatch(getCustomers())
	}, [dispatch])

	const handleSelected = (reportName: string | null): void => {
		const selectedReport = reports.find(report => report.name === reportName)
		if (selectedReport) {
			if (reportsToMask.includes(selectedReport?.name)) {
				const { radioButtons } = selectedReport
				const newRadioButtons = radioButtons.map(({ title, fields }) => ({
					title,
					fields: [...fields, ...(isSuperAdmin ? [COMMON_FIELDS.EMPLOYEE] : [])],
				}))
				const updatedReport = { ...selectedReport, radioButtons: newRadioButtons }
				setSelectedReport(updatedReport)
			} else {
				setSelectedReport(selectedReport)
			}
		}
	}

	const downloadFile = (fileUrl: string, fileName: string) => {
		const tempLink = document.createElement("a")
		tempLink.href = fileUrl
		tempLink.setAttribute("download", fileName)
		tempLink.click()
	}

	const addCompanyLogo = async (data: Blob) => {
		const existingPdfBytes = await data.arrayBuffer()
		const pdfDoc = await PDFDocument.load(existingPdfBytes)
		const pages = pdfDoc.getPages()

		const pngImage = await pdfDoc.embedJpg(timeTrailLogoBase64)
		const { width, height } = pngImage.scale(0.08) // Adjust the scale as needed
		const props = { x: 500, y: 780, width, height }

		pages.forEach(page => page.drawImage(pngImage, props))

		const pdfBytes = await pdfDoc.save()
		return new Blob([pdfBytes], { type: "application/pdf" })
	}

	const downloadFileFromBlob = async (data: Blob, fileType: string, name?: string) => {
		setLoading(false)
		const { header, fileName } = FILE_TYPES[fileType as keyof typeof FILE_TYPES]

		const blobData = new Blob([data], { type: header })

		if (fileType !== "pdf") {
			const fileURL = window.URL.createObjectURL(blobData)
			downloadFile(fileURL, name || fileName)
		} else {
			const pdfBlobWithLogon = await addCompanyLogo(blobData)

			const fileURL = window.URL.createObjectURL(pdfBlobWithLogon)
			setDownloadReport({ fileURL, fileName: name || fileName })
			setPdf(pdfBlobWithLogon)
		}
	}

	const handleCreateReport = async (name: string, data: any, additionalFields?: any, cb = () => undefined) => {
		let payload = {
			...data,
			startDate: moment(data.startDate)
				.set({ hour: 0, minute: 0, second: 0 })
				.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
			endDate: convertDateTimeToUTC(moment(data.endDate))
				.set({ hour: 23, minute: 59, second: 59 })
				.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
		}
		if (additionalFields) {
			Object.entries(valuesToAdd.find(value => value.report === name)?.values || {})
				.filter(propName => additionalFields.some((field: string) => field === propName[0].toString()))
				.forEach(array => (payload[array[0]] = array[1]))
		}
		setLoading(true)
		payload = Object.fromEntries(
			Object.entries(payload).map(([key, value]) => [caseConverters.camelCase(key), value]),
		)
		try {
			const res = await reportService.generateReport(reportNameToUrl[name], payload)
			downloadFileFromBlob(res.data, payload.type, caseConverters.pascalCase(name))
		} catch (error) {
			setLoading(false)
		}

		cb()
	}

	const handleDownloadPdf = () => {
		if (downloadReport) downloadFile(downloadReport.fileURL, downloadReport?.fileName)
	}

	return (
		<div>
			<GlobalSidebar>
				<SidebarList
					title={"Report"}
					selected={selectedReport?.name || ""}
					setSelected={handleSelected}
					list={reportsList}
				/>
			</GlobalSidebar>

			<div className="px-4">
				{pdf && (
					<div className="mx-auto w-full flex-row items-center justify-center">
						<PdfViewer pdf={pdf} setPdf={setPdf} handleDownloadPdf={handleDownloadPdf} />
						<div className="mt-10 flex flex-row-reverse ">
							<Button onClick={() => setPdf(null)}>Dismiss</Button>
						</div>
					</div>
				)}
				{selectedReport && <Report report={selectedReport} onSubmit={handleCreateReport} loading={loading} />}
			</div>
		</div>
	)
}

export default Index
