import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react"

import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"

import {
	deletingSchedule,
	acceptSchedule,
	selectScheduleListByLocationForTable,
	selectScheduleLoading,
	selectScheduleDelete,
	getSchedule,
	saveInactiveSchedule,
} from "store/Schedules/schedules.slice"
import ScheduleTable2 from "./ScheduleTableWrapper"
import { selectEmployeeDropDown } from "store/Employee/detail.slice"
import {
	selectSelectedLocationBranch,
	selectSelectedLocation,
	setSelectedLocationBranch,
	setSelectedEmployee,
	setSelectedLocation,
} from "store/globalState.slice"
import {
	AntdButton,
	AntdSelect1,
	ConfirmationModal,
	EmployeeInformationModal,
	RecurringModal,
	ScheduleTableSkeletonLocation,
} from "components"
import {
	isLocationContractsLoading,
	selectLocationContractsDataDropDown,
	selectContractById,
} from "store/Location/locationContracts.slice"
import LocationSidebar from "components/LocationSidebar"
import {
	createInactiveSchedulesFromRotaLoad,
	deleteInactiveSchedule,
	deleteInactiveSchedulePermanently,
	getLocationPostsByContractId,
	isLocationPostsSaving,
	selectPostsById,
	selectPostsByNameForSchedules,
} from "store/Location/locationPosts.slice"
import { clearLocationPosts } from "store/Location/locationPosts.slice"
import {
	AddDateIfFinishTimeIsGreater,
	convertDateTimeStringToIsoUtc,
	convertDateToFinishTimeUTC,
	convertDateToStartTimeUTC,
	getMomentZInstanceOfDate,
	getStartAndEndWeekDateFromCurrentDate,
	isDateSameOrBetween,
} from "config/utils"
import { DATE_FORMATS, Ongoing, ScheduleTypesEnum } from "config/constants"
import moment, { Moment } from "moment"
import { isLocationLoading, selectLocationById } from "store/Location/locationDetails.slice"
import { getLocationContractsByLocationId } from "store/Location/locationContracts.slice"
import EmployeeRetainContext, { CreateScheduleRetain, CreateScheduleRetainSource } from "./CreateScheduleRetainContext"
import ScheduleCrudBar from "../ScheduleCrudBar"
import CreateScheduleModal from "./CreateScheduleModal"
import AllocateScheduleModal from "./AllocateScheduleModal"
import useGetScheduleCurrentWeekHours from "hooks/useGetScheduleCurrentWeekHours"
import { GetSchedulesInterface, SelectedSchedules } from "@type/schedules.types"
import ScheduleInfo from "./ScheduleInfo"
import ShowEmployeeDetailModal from "./ShowEmployeeDetailModal"
import { DatePicker } from "antd"
import { ScheduleStatus } from "../Constants/schedulesConstants"
import { selectCurrentUser } from "store/auth.slice"
import IncidentModal from "pages/workMonitor/IncidentModal"
import WorkMonitorShifts from "services/workMonitor/workMonitor.service"
import { ShiftInterface } from "@type/workMonitor.types"
import { getSDIncidents } from "store/StaticData/SDIncidents.slice"
import { round } from "lodash"
import ScheduleAuditTrail from "./ScheduleAuditTrailModal"
import { ConfigProvider, Statistic, Divider } from "antd-v5"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { CaretDownOutlined } from "@ant-design/icons"
import { useHeightResize } from "hooks"
import { fetchRotaPlan, selectRotaPlannerData } from "store/RotaPlanner/rotaPlanner.slice"
import RotaLoadModal from "./RotaLoadModal"
import { RangeValue } from "rc-picker/lib/interface"
import { momentZ } from "config/moment"
import UnloadRotaModal from "./UnloadRotaModal"
import ShiftEventsRetainContext from "./ShiftEventsContext"

export interface DaysInWeekLabel {
	label: string
	date: string
	disable: boolean
}
interface props {
	parentDate: moment.Moment
	setParentDate: Dispatch<SetStateAction<moment.Moment>>
}

const Location: React.FC<props> = ({ parentDate, setParentDate }) => {
	const dispatch = useAppDispatch()
	useHeightResize({ element: "schedule-container" })
	//Refs
	const scheduleTableRef = useRef<() => void>(() => undefined)

	// Selected Cells State
	const [selectedCells, setSelectedCells] = useState<SelectedSchedules>({ key: null })

	const [selectedCellShift, setSelectedCellShift] = useState<ShiftInterface | null>(null)

	// Contexts
	// const { selectedCell, handleSelectedCell } = useContext(ScheduleContext) as ScheduleContextProps
	const selected = useAppSelector(selectSelectedLocation)

	// Parent Context state - Create Employee Modal Employee Selection Details State
	const [createScheduleModalData, setCreateScheduleModalData] = useState<CreateScheduleRetain>(null)

	// Selected Branch selector (Global State)
	const selectedBranch = useAppSelector(selectSelectedLocationBranch)

	// Location Selector
	const selectedLocation = useAppSelector(selectLocationById(selected))
	const loading = useAppSelector(isLocationLoading())

	//Post
	const inactiveScheduleLoading = useAppSelector(isLocationPostsSaving())

	// Employee Selector
	const employeesList = useAppSelector(selectEmployeeDropDown())

	// Component Local State
	const [daysInWeeksLabel, setDaysInWeeksLabel] = useState<DaysInWeekLabel[]>([])
	const [infoTime, setInfoTime] = useState<{ startTime: string; finishTime: string }>({
		startTime: "",
		finishTime: "",
	})
	const [resetFunc, setResetFunc] = useState<{ reset: () => void }>({
		reset: () => {
			return
		},
	})

	// Contracts Selector
	const isContractsLoading = useAppSelector(isLocationContractsLoading())
	const contractsList = useAppSelector(selectLocationContractsDataDropDown(selected))
	const [selectedContract, setSelectedContract] = useState<{ label: string; value: string }>(contractsList[0])
	const selectedContractDetails = useAppSelector(selectContractById(selectedContract.value))
	const [selectedDate, setSelectedDate] = useState<string>(moment(parentDate).format(DATE_FORMATS.DD_MM_YYYY))

	// Rota Plan Selector
	const rotaPlan = useAppSelector(selectRotaPlannerData)

	// Posts Selector
	const selectedContractPosts = useAppSelector(selectPostsByNameForSchedules(selectedContract.value))

	// User Selector
	const user = useAppSelector(selectCurrentUser())

	// Selects All Keys (Post) from Selected Cells
	const extractPostsFromSelectedCells = useMemo(() => {
		return Object.keys(selectedCells).map(row => {
			const postId = row.split("_")[0]
			return postId
		})
	}, [selectedCells])
	const postData = useAppSelector(selectPostsById(extractPostsFromSelectedCells[0]))

	// Modals State
	const [createScheduleModal, setCreateScheduleModal] = useState<boolean>(false)
	const [isUpdatingSchedule, setIsUpdatingSchedule] = useState<boolean>(false)
	const [allocationModalOpen, setAllocationModalOpen] = useState<boolean>(false)
	const [recurringModalOpen, setRecurringModalOpen] = useState<boolean>(false)
	const [deleteScheduleModal, setDeleteScheduleModal] = useState<boolean>(false)
	const [informationModalOpen, setInformationModalOpen] = useState<boolean>(false)
	const [isRecurring, setIsRecurring] = useState<boolean>(false)
	const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false)
	const [openIncidentModal, setOpenIncidentModal] = useState<boolean>(false)
	const [loadingShift, setLoadingShift] = useState<boolean>(false)
	const [showAuditScheduleModal, setShowAuditScheduleModal] = useState<boolean>(false)
	const [openRotaLoadModal, setOpenRotaLoadModal] = useState<boolean>(false)
	const [openRotaUnloadModal, setOpenRotaUnloadModal] = useState<boolean>(false)
	const [shiftBySchedule, setShiftBySchedule] = useState<ShiftInterface | null>(null)

	// Show Deleted Schedules
	const [showDeletedSchedule, setShowDeletedSchedule] = useState<boolean>(false)
	// Schedule Selector
	const schedules = useAppSelector(
		selectScheduleListByLocationForTable(showDeletedSchedule, selectedDate, selectedContractDetails),
	) //selectedContract.value
	const isScheduleLoading = useAppSelector(selectScheduleLoading())
	const isScheduleDeleting = useAppSelector(selectScheduleDelete())

	// Hooks
	const totalTime = useGetScheduleCurrentWeekHours(
		schedules.filter(schedule => schedule.status !== ScheduleStatus.INACTIVE) as GetSchedulesInterface[],
	)

	const [employeeRowId, setEmployeeRowId] = useState("")

	const handleCreateScheduleModalData = (data: CreateScheduleRetain) => setCreateScheduleModalData(data)

	const fetchShiftByScheduleId = async (scheduleId: string) => {
		if (scheduleId) {
			const { data: shifts } = await WorkMonitorShifts.getScheduleShiftEvents(scheduleId)
			setShiftBySchedule({ ...shifts[0] })
		} else setShiftBySchedule(null)
	}

	// Selects All data from Selected Cells
	const extractSelectedCellsData = useMemo(() => {
		return Object.values(selectedCells)
	}, [selectedCells])

	//Disable Auto Button
	const disableAuto = useMemo(() => {
		setInfoTime({
			startTime: extractSelectedCellsData?.[0]?.startTime || "",
			finishTime: extractSelectedCellsData?.[0]?.finishTime || "",
		})
		return (
			!extractSelectedCellsData.length ||
			!extractSelectedCellsData.every(schedule => schedule?.status === ScheduleStatus.INACTIVE)
		)
	}, [extractSelectedCellsData])

	const inActiveSchedulesSelected = useMemo(() => {
		return extractSelectedCellsData?.some(schedule => schedule?.status === ScheduleStatus.INACTIVE)
	}, [extractSelectedCellsData])

	// Disables Update, Allocate, Accept, Incident, Delete if all selected cells does not contain schedules
	const disableButtons = useMemo(() => {
		return !!extractSelectedCellsData.filter(cell => cell === null).length || !extractSelectedCellsData.length
	}, [extractSelectedCellsData])

	useEffect(() => {
		dispatch(getSDIncidents())
	}, [])

	useEffect(() => {
		if (selectedDate) setParentDate(moment(selectedDate, DATE_FORMATS.DD_MM_YYYY))
	}, [selectedDate])

	useEffect(() => {
		handleCreateScheduleModalData(null)
		if (extractSelectedCellsData[0]) {
			dispatch(setSelectedEmployee(extractSelectedCellsData[0]?.employee))
		}
	}, [selectedCells, extractSelectedCellsData])

	useEffect(() => {
		if (selectedContract.value) {
			const weekList = setWeekDays(selectedDate, false)
			setDaysInWeeksLabel(weekList)
			dispatch(fetchRotaPlan(selectedContract.value))
		}
	}, [selectedContract])

	useEffect(() => {
		resetTable()
		if (selected) {
			dispatch(getLocationContractsByLocationId(selected))
		}
	}, [selected])

	useEffect(() => {
		if (selectedLocation && selectedLocation.branch !== selectedBranch) {
			dispatch(setSelectedLocationBranch(selectedLocation?.branch._id || ""))
		}
	}, [selectedLocation, selected])

	// Automatically sets the first contract list
	useEffect(() => {
		const selectFirstContract =
			contractsList.length > 1
				? contractsList.find(c => !c?.finishDate || c?.finishDate > moment().startOf("d")) || contractsList[1]
				: ""
		if (selectFirstContract) onContractSelection(selectFirstContract.value, selectFirstContract.label)
	}, [contractsList.length])

	const onCreateSchedules = () => setCreateScheduleModal(true)

	const onRecurringSchedules = () => {
		if (!Object.values(selectedCells).some(item => !!item?._id && item?.status !== ScheduleStatus.INACTIVE)) {
			setIsRecurring(true)
			setCreateScheduleModal(true)
		} else {
			setRecurringModalOpen(true)
		}
	}

	const onAcceptSchedule = () => {
		const payload = extractSelectedCellsData
			.filter(schedule => schedule !== null)
			.map(schedule => schedule?._id || "")

		dispatch(acceptSchedule(payload, () => resetTable()))
	}

	const onIncidentReport = async () => {
		setOpenIncidentModal(true)

		if (selectedCellShift?.scheduleId !== extractSelectedCellsData[0]?._id) {
			setLoadingShift(true)
			const shifts = await WorkMonitorShifts.getScheduleShiftEvents(extractSelectedCellsData[0]?._id ?? "")
			setSelectedCellShift({ ...shifts.data[0], locationId: selected })
			setLoadingShift(false)
		}
	}

	const onCreateInActiveSchedule = () => {
		const extractedData = Object.values(selectedCells).filter(
			inActiveSchedule => inActiveSchedule?.status === ScheduleStatus.INACTIVE,
		)
		const payload: any[] = []
		extractedData.forEach(data => {
			payload.push({
				...(data?.employee && { employee: data?.employee }),
				location: selected,
				posts: [
					{
						postId: data?.post || "",
						dates: [
							{
								startTime: convertDateTimeStringToIsoUtc(
									data?.startTime || "",
									DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1,
								),
								finishTime: convertDateTimeStringToIsoUtc(
									data?.finishTime || "",
									DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1,
								),
							},
						],
					},
				],
				type: (data?.type as ScheduleTypesEnum) || "",
				user: user?._id,
				createdFromRecur: data?._id,
			})
		})

		dispatch(saveInactiveSchedule(payload, () => resetTable()))
	}

	const onUpdateSchedules = () => {
		setIsUpdatingSchedule(true)
		setCreateScheduleModal(true)
	}
	const onContractSelection = (value: string, label: string) => {
		setSelectedContract({ label, value })
		value ? dispatch(getLocationPostsByContractId(value)) : dispatch(clearLocationPosts())
		resetTable()
	}
	const handleSelected = (location: string) => dispatch(setSelectedLocation(location))

	const currentContractWeeks = useMemo(() => {
		if (selectedContract.value) {
			const [contractStartDate] = selectedContract.label.split(" - ")

			let getCurrentWeek = null

			const DateDiff = moment(selectedDate, DATE_FORMATS.DD_MM_YYYY).diff(
				moment(contractStartDate, DATE_FORMATS.DD_MM_YYYY),
				"days",
			)

			getCurrentWeek = DateDiff < 0 && DateDiff > -7 ? 1 : DateDiff / 7 + 1

			getCurrentWeek = Math.round(getCurrentWeek)

			return getCurrentWeek
		}
	}, [selectedContract, selectedDate])

	const setWeekDays = (currentDate: string, shouldChangeDate = true) => {
		const [contractStartDate, contractEndDate] = selectedContract.label.split(" - ")
		const data = []

		const [startWeekDate, weekEndDate] = getStartAndEndWeekDateFromCurrentDate(
			moment(currentDate, DATE_FORMATS.DD_MM_YYYY).format(DATE_FORMATS.DD_MM_YYYY),
		)

		let currentWeekDate = startWeekDate

		if (shouldChangeDate) setSelectedDate(currentWeekDate)

		while (
			moment(currentWeekDate, DATE_FORMATS.DD_MM_YYYY).isSameOrBefore(
				moment(weekEndDate, DATE_FORMATS.DD_MM_YYYY),
			)
		) {
			data.push({
				label: currentWeekDate,
				date: currentWeekDate,
				disable:
					contractEndDate === Ongoing
						? moment(contractStartDate, DATE_FORMATS.DD_MM_YYYY).isAfter(
								moment(currentWeekDate, DATE_FORMATS.DD_MM_YYYY),
						  )
						: !isDateSameOrBetween(currentWeekDate, contractStartDate, contractEndDate),
			})
			currentWeekDate = moment(currentWeekDate, DATE_FORMATS.DD_MM_YYYY)
				.add(1, "day")
				.format(DATE_FORMATS.DD_MM_YYYY)
		}

		if (selected) {
			dispatch(
				getSchedule({
					startTime: convertDateToStartTimeUTC(data[0]?.label.split("-").reverse().join("")),
					finishTime: convertDateToFinishTimeUTC(data[6]?.label.split("-").reverse().join("")),
					location: selected,
					contract: selectedContract.value,
				}),
			)
		}

		return data
	}

	const infoModalDetails = useMemo(() => {
		const selectedInfoDetails = Object.keys(selectedCells ?? extractSelectedCellsData[0])
			.filter(item => selectedCells[item] !== null)
			.map(scheduleCellData => {
				const scheduleDate = scheduleCellData.split("_")[1]

				const starTimeMoment = convertDateTimeStringToIsoUtc(
					`${scheduleDate} ${moment(infoTime.startTime, "HH:mm").format("HH:mm")}`,
				)
				let finishTimeMoment = convertDateTimeStringToIsoUtc(
					`${scheduleDate} ${moment(infoTime.finishTime, "HH:mm").format("HH:mm")}`,
				)
				finishTimeMoment = AddDateIfFinishTimeIsGreater(starTimeMoment, finishTimeMoment)
				return { startTime: starTimeMoment, finishTime: finishTimeMoment }
			})

		return selectedInfoDetails
	}, [infoTime, selectedCells, extractSelectedCellsData])

	// Reset state After Dispatching
	const resetTable = () => {
		setCreateScheduleModal(false)
		setIsUpdatingSchedule(false)
		setAllocationModalOpen(false)
		setRecurringModalOpen(false)
		setDeleteScheduleModal(false)
		setIsRecurring(false)
		setCreateScheduleModalData(null)
		scheduleTableRef.current()
	}

	const onDateChange = (selectedDate: moment.Moment | null) => {
		if (selectedDate) {
			setSelectedDate(moment(selectedDate, DATE_FORMATS.DD_MM_YYYY).format(DATE_FORMATS.DD_MM_YYYY))
			const weekList = setWeekDays(
				moment(selectedDate, DATE_FORMATS.DD_MM_YYYY).format(DATE_FORMATS.DD_MM_YYYY),
				false,
			)
			setDaysInWeeksLabel(weekList)
		}
	}

	const handleNextHandler = () => {
		const nextWeekDate = moment(selectedDate, DATE_FORMATS.DD_MM_YYYY)
			.add(1, "week")
			.format(DATE_FORMATS.DD_MM_YYYY)

		const weekList = setWeekDays(nextWeekDate)
		setDaysInWeeksLabel(weekList)

		resetTable()
	}

	const handlePrevHandler = () => {
		const previousWeekDate = moment(selectedDate, DATE_FORMATS.DD_MM_YYYY)
			.subtract(1, "week")
			.format(DATE_FORMATS.DD_MM_YYYY)

		const weekList = setWeekDays(previousWeekDate)
		setDaysInWeeksLabel(weekList)

		resetTable()
	}

	useEffect(() => {
		if (selectedDate) setParentDate(moment(selectedDate, DATE_FORMATS.DD_MM_YYYY))
	}, [selectedDate])

	const handleDeleteSchedule = (notes: string) => {
		const inActiveSchedulePayload: { [key: string]: string[] } = extractSelectedCellsData
			.filter(schedule => schedule?.status === ScheduleStatus.INACTIVE)
			.reduce((acc, schedule, _, arr) => {
				return {
					[schedule?.post || ""]:
						arr
							.filter(inActiveSchedule => schedule?.post === inActiveSchedule?.post)
							.map(inActiveSchedule => ({
								_id: inActiveSchedule?._id,
								until: getMomentZInstanceOfDate(schedule?.startTime)
									.clone()
									.subtract(1, schedule?.isFourOnFourOff ? "d" : "w")
									.endOf("day")
									.utc()
									.toDate(),
							})) || [],
					...acc,
				}
			}, {})

		const activeSchedulePayload = {
			ids: extractSelectedCellsData
				.filter(schedule => schedule?.status !== ScheduleStatus.INACTIVE)
				.map(schedule => schedule?._id || ""),
			notes,
		}

		if (activeSchedulePayload.ids.length) {
			dispatch(
				deletingSchedule(activeSchedulePayload, () => {
					setDeleteScheduleModal(false)
				}),
			)
		}
		if (Object.keys(inActiveSchedulePayload).length)
			dispatch(
				deleteInactiveSchedule(inActiveSchedulePayload, () => {
					setDeleteScheduleModal(false)
				}),
			)
	}

	const handleDeleteInactiveSchedulesByRotaLoad = (date: Moment) => {
		dispatch(
			deleteInactiveSchedulePermanently(selectedContract.value, date.startOf("day").toISOString(), () =>
				setOpenRotaUnloadModal(false),
			),
		)
	}

	const submitRotaLoad = (date: RangeValue<moment.Moment>) => {
		if (date) {
			const [startTime, finishTime] = date
			dispatch(
				createInactiveSchedulesFromRotaLoad(
					{
						startTime: momentZ(startTime).toISOString(),
						finishTime: momentZ(finishTime).toISOString(),
						contract: selectedContract.value,
						location: selected,
					},
					() => {
						setOpenRotaLoadModal(false)
						onDateChange(moment(startTime))
					},
				),
			)
		}
	}

	const stats = useMemo(() => {
		return [
			{
				title: "Site",
				value: [
					selectedLocation?.name,

					selectedLocation?.addressLine1,
					selectedLocation?.addressLine2,
					selectedLocation?.postalCode,
				]
					.filter(str => !!str)
					.join(", "),
				className: "col-span-3",
			},
			{ title: "Customer", value: selectedLocation?.customer?.name || "N/A", className: "col-span-2" },
			{ title: "Client SIN", value: selectedLocation?.clientSin || "N/A" },
			{ title: "Contract Week", value: currentContractWeeks || "N/A" },
			{ title: "Contracted Hours", value: selectedContractDetails?.hoursPerWeek || "N/A" },
			{ title: "Scheduled Hours", value: round(+totalTime?.showTotalTime.totalTime, 2) },
			{ title: "Unallocated Hours", value: round(+totalTime?.showTotalUnAllocatedTime.totalTime, 2) },
			{
				title: "Variance",
				value: round((selectedContractDetails?.hoursPerWeek || 0) - totalTime.showTotalTime.totalTime, 2),
			},
		]
	}, [selectedLocation, selectedContractDetails, totalTime, currentContractWeeks])

	return (
		<ShiftEventsRetainContext.Provider value={{ shift: shiftBySchedule, fetchShiftByScheduleId }}>
			<EmployeeRetainContext.Provider value={{ createScheduleModalData, handleCreateScheduleModalData }}>
				<LocationSidebar
					selected={selected}
					handleSelected={locationId => {
						handleSelected(locationId)
					}}
					needEmployeeByBranch={false}
				/>
				<ScheduleCrudBar
					onCreateSchedules={onCreateSchedules}
					onRecurringSchedules={onRecurringSchedules}
					onAcceptSchedule={onAcceptSchedule}
					onUpdateSchedules={onUpdateSchedules}
					onAllocateSchedule={() => setAllocationModalOpen(true)}
					onCreateInActiveSchedule={onCreateInActiveSchedule}
					onIncidentReport={onIncidentReport}
					onDeleteSchedule={() => setDeleteScheduleModal(true)}
					onAuditSchedule={() => setShowAuditScheduleModal(true)}
					onRotaLoad={() => setOpenRotaLoadModal(true)}
					onRotaUnload={() => setOpenRotaUnloadModal(true)}
					showDeletedSchedules={val => setShowDeletedSchedule(val)}
					disableCreate={!extractSelectedCellsData.length}
					disableUpdate={disableButtons || inActiveSchedulesSelected}
					disableAccept={disableButtons || inActiveSchedulesSelected}
					disableAuto={disableAuto}
					disableAllocate={disableButtons || inActiveSchedulesSelected}
					disableDelete={disableButtons}
					disableIncident={disableButtons || extractSelectedCellsData.length > 1}
					disableAudit={extractSelectedCellsData[0]?._id || ""}
					disableRotaLoad={!rotaPlan?._id}
				/>
				<ConfigProvider
					prefixCls="ant5"
					theme={{
						components: {
							Divider: {
								marginLG: 10,
							},
						},
					}}
				>
					<Divider />
					<div className="grid h-full grid-cols-11">
						{stats.map(({ title, value, className = "col-span-1" }) => (
							<Statistic
								key={title}
								title={<span className="font-semibold 3xl:text-md">{title}</span>}
								value={value}
								className={className}
								formatter={val => (
									<span className="font-semibold text-gray-800 3xl:text-md">{val}</span>
								)}
							/>
						))}
					</div>
					<Divider />

					<div id="schedule-container" className="grid grid-cols-12 gap-x-1 3xl:gap-x-4">
						<div id="schedule-table-container" className="col-span-9">
							{isScheduleLoading || loading ? (
								<ScheduleTableSkeletonLocation />
							) : selectedContract.value ? (
								<ScheduleTable2
									ref={scheduleTableRef}
									data={schedules}
									columnHeaderKey="date"
									rowHeaderKey="post"
									rowHeaderLabels={selectedContractPosts}
									columnHeaderLabels={daysInWeeksLabel}
									noDataText={""}
									multipleSingleSelection={false}
									selected={setSelectedCells}
								/>
							) : (
								<div className="py-10 text-center text-xl font-semibold">No Contract Selected</div>
							)}
						</div>
						<div id="schedule-info-container" className="col-span-3 space-y-4">
							<AntdSelect1
								disabled={!contractsList.length || !selected}
								onChange={event => {
									const selectedOptionsLabel =
										contractsList.find(({ value }) => value === event)?.label || ""
									onContractSelection(event, selectedOptionsLabel)
								}}
								value={selectedContract}
								loading={isContractsLoading}
								placeholder="Select Contract"
								options={contractsList || []}
								suffixIcon={
									<>
										<span style={{ color: "rgb(156 163 175)" }}>Contract</span>
										<CaretDownOutlined style={{ color: "rgb(156 163 175)" }} />
									</>
								}
							/>
							<div className="flex items-center justify-between space-x-2">
								<AntdButton
									onClick={handlePrevHandler}
									shape="circle"
									icon={<MdChevronLeft size={20} />}
								/>
								<DatePicker
									className="grow"
									disabled={isContractsLoading || loading}
									value={moment(selectedDate, DATE_FORMATS.DD_MM_YYYY)}
									format={DATE_FORMATS.DD_MM_YYYY}
									placeholder={DATE_FORMATS.DD_MM_YYYY}
									onChange={onDateChange}
								/>
								<AntdButton
									onClick={handleNextHandler}
									shape="circle"
									icon={<MdChevronRight size={20} />}
								></AntdButton>
							</div>
							<div>
								{extractSelectedCellsData.length === 1 &&
								extractSelectedCellsData[0] !== null &&
								"employee" in extractSelectedCellsData[0] ? (
									<ScheduleInfo data={extractSelectedCellsData[0]} />
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
				</ConfigProvider>

				{/* <div>
				{extractSelectedCellsData.length ? (
					<Button className="ml-auto h-fit" onClick={() => resetTable()}>
						Clear
					</Button>
				) : undefined}
			</div> */}

				{recurringModalOpen && (
					<RecurringModal
						open={recurringModalOpen}
						selectedSchedule={selectedCells}
						modalHandler={close => {
							setRecurringModalOpen(close)
							if (createScheduleModalData) {
								setCreateScheduleModal(true)
								setIsRecurring(true)
							}
						}}
						resetTable={resetTable}
					/>
				)}
				{createScheduleModal && (
					<CreateScheduleModal
						showInfo={{ location: selectedLocation?.name || "" }}
						postData={postData || null}
						open={createScheduleModal}
						isRecurring={isRecurring}
						isUpdating={isUpdatingSchedule}
						selectedSchedule={selectedCells}
						modalHandler={(close: boolean) => {
							setCreateScheduleModal(close)
							setIsUpdatingSchedule(false)
							setIsRecurring(false)
						}}
						employeeList={employeesList}
						employeeRowId={employeeRowId}
						infoModalHandler={(close: boolean) => setInformationModalOpen(close)}
						infoModalData={(startTime: string, finishTime: string, reset: () => void) => {
							setInfoTime({ startTime, finishTime })
							setResetFunc({ reset })
						}}
						setRecurringModalOpen={setRecurringModalOpen}
						setShowEmployeeModal={setShowEmployeeModal}
						resetTable={resetTable}
					/>
				)}
				{allocationModalOpen && (
					<AllocateScheduleModal
						showInfo={{ location: selectedLocation?.name || "" }}
						postData={postData || null}
						open={allocationModalOpen}
						modalHandler={(close: boolean) => {
							handleCreateScheduleModalData(null)
							setAllocationModalOpen(close)
						}}
						infoModalHandler={(close: boolean) => setInformationModalOpen(close)}
						resetSelection={(reset: () => void) => {
							setResetFunc({ reset })
						}}
						selectedSchedules={extractSelectedCellsData}
						setShowEmployeeModal={setShowEmployeeModal}
						resetTable={resetTable}
					/>
				)}
				{deleteScheduleModal && (
					<ConfirmationModal
						confirmationAction={false}
						open={deleteScheduleModal}
						loader={isScheduleDeleting}
						confirmationText={"delete"}
						modalHandler={(close: boolean) => setDeleteScheduleModal(close)}
						onConfirmation={(notes: string) => {
							handleDeleteSchedule(notes)
							resetTable()
						}}
					/>
				)}

				{openIncidentModal && (
					<IncidentModal
						open={openIncidentModal}
						modalHandler={close => setOpenIncidentModal(close)}
						selectedRow={selectedCellShift}
						loading={loadingShift}
					/>
				)}

				{showEmployeeModal && (
					<ShowEmployeeDetailModal
						open={showEmployeeModal}
						modalHandler={close => {
							setShowEmployeeModal(close)
							if (createScheduleModalData?.source === CreateScheduleRetainSource.VIEW_EMPLOYEE_DETAILS)
								setCreateScheduleModal(true)
							if (createScheduleModalData?.source === CreateScheduleRetainSource.ALLOCATE)
								setAllocationModalOpen(true)
						}}
					/>
				)}
				{informationModalOpen && (
					<EmployeeInformationModal
						filterEmployeeType={postData?.type || ""}
						open={informationModalOpen}
						modalHandler={setInformationModalOpen}
						rowSelectHandler={setEmployeeRowId}
						locationId={selected}
						reset={resetFunc.reset}
						data={infoModalDetails}
					/>
				)}
				{/* {showAuditScheduleModal && ( */}
				<ScheduleAuditTrail
					modalHandler={setShowAuditScheduleModal}
					open={showAuditScheduleModal}
					scheduleId={extractSelectedCellsData[0]?._id || ""}
				/>
				{/* )} */}

				{openRotaLoadModal && (
					<RotaLoadModal
						open={openRotaLoadModal}
						modalHandler={setOpenRotaLoadModal}
						selectedContract={selectedContractDetails || null}
						submitRotaLoad={submitRotaLoad}
					/>
				)}

				{openRotaUnloadModal && (
					<UnloadRotaModal
						open={openRotaUnloadModal}
						loader={inactiveScheduleLoading}
						modalHandler={setOpenRotaUnloadModal}
						onConfirmation={date => {
							handleDeleteInactiveSchedulesByRotaLoad(date)
							resetTable()
						}}
					/>
				)}
			</EmployeeRetainContext.Provider>
		</ShiftEventsRetainContext.Provider>
	)
}

export default Location
