import React, { useEffect, useMemo, useRef, useState } from "react"
import {
	GlobalSidebar,
	// Button,
	SidebarList,
	// Table2,
	AntdSelect,
	FormInputAntd,
	FormSelectAntd,
	AntdButton,
	FormCheckbox,
	AntdInput,
} from "components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { contactDetailsInitialValues, customerInitialValues, customerSchema } from "Schema"
import { CreateCustomersInterface } from "@type/customers.types"
import useAppSelector from "hooks/useAppSelector"
import useAppDispatch from "hooks/useAppDispatch"
import {
	filteredCustomerListBySearch,
	getCustomers,
	isCustomerLoading,
	isCustomerSaving,
	saveCustomer,
	selectCustomerById,
} from "store/Customers/customers.slice"
import { cloneDeep } from "lodash"
// import { ContactMethodsOptions } from "config/constants"
import { getSDCounties } from "store/StaticData/SDCounties.slice"
import { selectSDCountiesDropdownList } from "store/StaticData/SDCounties.slice"
import { selectSDCompaniesDropdownList } from "store/StaticData/SDCompanies.slice"
import { selectCurrentUser } from "store/auth.slice"
import { getSDQualifications, selectSDQualificationsList } from "store/StaticData/SDQualifications.slice"
import { SearchOutlined } from "@ant-design/icons"
import { Divider } from "antd-v5"

interface CustomersFormHandler extends CreateCustomersInterface {}

const Customers = () => {
	const [selectedCustomer, setSelectedCustomer] = useState<string | null>("")
	const [selectedContactDetail, setSelectedContactDetail] = useState<string | null>("")
	const [savingCustomer, setSavingCustomer] = useState<boolean>(false)
	const [savingContactDetail, setSavingContactDetail] = useState<boolean>(false)
	const [search, setSearch] = useState<string>("")

	const dispatch = useAppDispatch()
	const loading = useAppSelector(isCustomerLoading())
	const saving = useAppSelector(isCustomerSaving())
	const customer = useAppSelector(selectCustomerById(selectedCustomer))
	const allCounties = useAppSelector(selectSDCountiesDropdownList) || []
	const allCompanies = useAppSelector(selectSDCompaniesDropdownList) || []
	const SDQualificationsList = useAppSelector(selectSDQualificationsList)
	const user = useAppSelector(selectCurrentUser())

	const filteredCustomerSearch = useAppSelector(filteredCustomerListBySearch(search, ["name"]))

	const isNewBeingCreated = useRef(false)

	const methods = useForm<CustomersFormHandler>({ resolver: yupResolver(customerSchema) })
	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { errors, dirtyFields, isSubmitting },
	} = methods
	const contactDetail = useMemo(() => {
		if (!selectedCustomer || !selectedContactDetail) return contactDetailsInitialValues
		return customer?.contactDetails?.find(item => item?._id === selectedContactDetail)
	}, [selectedCustomer, selectedContactDetail])

	const shouldSubmitContactDetailsForm = useMemo(() => {
		const { contactDetail = {} } = dirtyFields
		const { name, position, contactMethod, phoneOrEmail } = contactDetail
		// This will enable the update contact button when contact is being updated
		if (selectedContactDetail) return Object.values(contactDetail)?.some(field => field === true)
		else if (selectedCustomer && name && position && contactMethod && phoneOrEmail) return true // This will enable the save contact button
		return false
	}, [cloneDeep(dirtyFields.contactDetail), selectedContactDetail])

	const shouldSubmitCustomerForm = useMemo(() => {
		const customerDirtyFields = { ...dirtyFields }
		delete customerDirtyFields.contactDetail
		if (selectedCustomer) return Object.values(customerDirtyFields)?.some(field => field === true)
		else return Object.values(customerDirtyFields).every(field => field === true)
	}, [cloneDeep(dirtyFields), selectedCustomer])

	const customerList = useMemo(() => {
		return filteredCustomerSearch?.map(PBCode => ({ _id: PBCode._id, name: PBCode.name }))
	}, [saving, filteredCustomerSearch?.length, search])

	const SDQualificationsOptions = useMemo(() => {
		return SDQualificationsList?.map(SDQualification => ({
			label: SDQualification.name,
			value: SDQualification._id,
		}))
	}, [SDQualificationsList?.length])

	const handleSelected = (customer: string | null): void => {
		setSelectedCustomer(customer)
		setSelectedContactDetail(null)
	}

	const onSubmit: SubmitHandler<CustomersFormHandler> = data => {
		let payload = data as any
		let organization
		if (user?.roles.filter(role => role.organization).length === 1) {
			organization = user?.roles[0]?.organization ?? ""
		} else {
			organization = data.organization
		}
		setSavingCustomer(true)

		if (!shouldSubmitContactDetailsForm || savingCustomer) {
			delete payload.contactDetail
		} else if (!shouldSubmitCustomerForm || savingContactDetail) {
			payload = { _id: payload._id, contactDetail: payload.contactDetail }
		}

		isNewBeingCreated.current = true
		dispatch(
			saveCustomer({ ...payload }, organization, id => {
				if (shouldSubmitContactDetailsForm && savingContactDetail) {
					setSavingContactDetail(false)
					reset({ contactDetail: contactDetailsInitialValues })
				} else setSavingCustomer(false)
				setSelectedCustomer(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getCustomers(setSelectedCustomer, true))
		dispatch(getSDCounties())
		dispatch(getSDQualifications())
	}, [])

	useEffect(() => {
		if (selectedCustomer !== (null && undefined)) {
			const customerProps = cloneDeep(customer)
			delete customerProps?.contactDetails
			reset({ ...customerProps, ...(contactDetail ? { contactDetail } : {}) })
		} else if (selectedCustomer === null && !isNewBeingCreated.current) {
			reset({
				...customerInitialValues,
				...(user?.roles.length
					? { organization: user.roles[0]?.organization ?? allCompanies?.[1]?.value }
					: {}),
			})
		} else if (!selectedContactDetail) {
			setValue("contactDetail", contactDetailsInitialValues)
		}
	}, [selectedCustomer, selectedContactDetail])

	return (
		<>
			<GlobalSidebar>
				<AntdInput
					onChange={e => setSearch(e.target.value)}
					placeholder="Search"
					type="text"
					suffix={<SearchOutlined size={14} style={{ color: "rgb(156 163 175)" }} />}
				/>
				<Divider className="bg-slate-700" style={{ margin: "15px 0 10px 0" }} />
				<SidebarList
					title={"Customers"}
					loading={loading}
					selected={selectedCustomer}
					setSelected={handleSelected}
					list={customerList}
				/>
			</GlobalSidebar>
			<div className="w-full">
				<div className="flex-col">
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="space-y-2">
								<div className="grid grid-cols-4 gap-y-3 gap-x-4">
									{user?.roles.some(role => role.isSuperAdmin) || user?.isSuperAdmin ? (
										<FormSelectAntd
											disabled={!!selectedCustomer}
											value={
												selectedCustomer ? `${customer?.organization}` : watch("organization")
											}
											label="Company"
											name="organization"
											options={allCompanies ?? []}
										/>
									) : (
										<></>
									)}
									<FormInputAntd type="text" label="Customer Name" name="name" />
									<FormInputAntd type="text" label="Telephone" name="telephone" />
									<FormInputAntd type="text" label="Address Line 1" name="addressLine1" />
									<FormInputAntd type="text" label="Address Line 2" name="addressLine2" />
									<FormSelectAntd
										disabled={!allCounties?.length}
										label="County"
										name="county"
										options={allCounties}
									/>
									<FormInputAntd type="text" label="City" name="city" />
									<FormInputAntd type="text" label="Post Code" name="postalCode" />
									<FormInputAntd type="text" label="Reference 1" name="reference1" />
									<FormInputAntd type="text" label="Reference 2" name="reference2" />
									<AntdSelect
										size="middle"
										disabled={!SDQualificationsOptions?.length}
										label="Required Qualifications"
										name="requiredQualifications"
										mode={"multiple"}
										formValueSetter={setValue}
										selected={watch("requiredQualifications") ?? []}
										isControlled={true}
										options={SDQualificationsOptions}
										errors={errors}
										placeholder=""
									/>
								</div>
								<FormCheckbox label="Active" name="active" />
								<FormCheckbox
									label="Enable Consecutive WorkDays Check"
									name="enableConsecutiveWorkDaysCheck"
								/>

								<div className="flex gap-x-2">
									<AntdButton
										onClick={() => {
											setSelectedCustomer(null)
											setSelectedContactDetail(null)
										}}
									>
										New
									</AntdButton>
									<AntdButton
										disabled={!shouldSubmitCustomerForm || isSubmitting}
										loading={savingCustomer}
										htmlType="submit"
									>
										{selectedCustomer ? "Update" : "Save"}
									</AntdButton>
								</div>
							</div>
							{/* <div className="flex">
								<div className="w-1/2 space-y-2">
									<div className="mt-5 text-2xl font-semibold">Contact Details</div>
									<div className="grid w-full grid-cols-2 gap-y-3 gap-x-4">
										<FormInputAntd type="text" label="Contact Name" name="contactDetail.name" />
										<FormInputAntd type="text" label="Position" name="contactDetail.position" />
										<FormSelectAntd
											disabled={!ContactMethodsOptions.length}
											label="Contact Method"
											name="contactDetail.contactMethod"
											options={ContactMethodsOptions}
										/>
										<FormInputAntd
											type="text"
											label="Phone/Email"
											name="contactDetail.phoneOrEmail"
										/>
									</div>

									<div className="flex w-full flex-row space-x-5">
										<FormCheckbox
											label="Is Primary Contact"
											name="contactDetail.isPrimaryContact"
										/>
										<FormCheckbox label="Is Active" name="contactDetail.isActive" />
									</div>
									<div className="flex gap-2">
										<AntdButton onClick={() => setSelectedContactDetail("")}>New</AntdButton>
										<AntdButton
											disabled={!shouldSubmitContactDetailsForm || isSubmitting}
											loading={savingContactDetail}
											onClick={() => setSavingContactDetail(true)}
										>
											{selectedContactDetail ? "Update" : "Save"}
										</AntdButton>
									</div>
								</div>
								<div className="mt-1.25 w-1/2">
									<Table2
										rows={customer?.contactDetails ? customer?.contactDetails : []}
										columns={[
											{
												label: "Sr. No",
												key: "serialNumber",
												render: (data: any) => (
													<div>
														{customer &&
															customer?.contactDetails &&
															customer?.contactDetails?.indexOf(data.row) + 1}
													</div>
												),
											},
											{
												label: "Name",
												key: "name",
												render: ({ row, key }: any) => <div>{row[key]}</div>,
											},
											{
												label: "Position",
												key: "position",
												render: ({ row, key }: any) => <div> {row[key]}</div>,
											},
											{
												label: "Method",
												key: "contactMethod",
												render: ({ row, key }: any) => <div> {row[key]}</div>,
											},
											{
												label: "Phone/Email",
												key: "phoneOrEmail",
												render: ({ row, key }: any) => <div> {row[key]}</div>,
											},
											{
												label: "Actions",
												key: "action",
												render: ({ row }: any) => {
													return (
														<Button
															type="button"
															className="h-9 py-1"
															onClick={() => setSelectedContactDetail(row._id)}
														>
															Update
														</Button>
													)
												},
											},
										]}
									/>
								</div>
							</div> */}
						</form>
					</FormProvider>
				</div>
			</div>
		</>
	)
}

export default Customers
