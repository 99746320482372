import { AntdButton, DateRangePicker, Modal } from "components"
import React, { useState } from "react"
import { RangeValue } from "rc-picker/lib/interface"
import moment, { Moment } from "moment"
import { DATE_FORMATS } from "config/constants"
import { GetLocationContractsInterface } from "@type/locations.types"
import { isLocationPostsSaving } from "store/Location/locationPosts.slice"
import { useAppSelector } from "hooks"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedContract: GetLocationContractsInterface | null
	submitRotaLoad: (date: RangeValue<Moment>) => void
}

const RotaLoadModal: React.FC<Props> = ({ open, modalHandler, selectedContract, submitRotaLoad }) => {
	const [dateRange, setDateRange] = useState<RangeValue<Moment>>(null)
	const isRotaLoading = useAppSelector(isLocationPostsSaving())

	return (
		<Modal
			open={open}
			modalHandler={modalHandler}
			title={
				<div className="py-3 text-center text-md font-bold uppercase tracking-wider text-dark">Rota Load</div>
			}
			containerClass="min-w-[500px]"
		>
			<div className="flex flex-col justify-center space-y-16">
				<div>
					<p>Note: Rota Load will start on Monday</p>
					<DateRangePicker
						picker="date"
						format={DATE_FORMATS.DD_MM_YYYY}
						size="middle"
						value={dateRange}
						disabledDate={date => {
							if (selectedContract?.startDate && selectedContract?.finishDate)
								return (
									date.isBetween(
										moment(selectedContract.startDate).add(1, "day"),
										moment(selectedContract.finishDate).subtract("1", "day"),
									) || date.weekday() !== 0
								)
							else if (!selectedContract?.finishDate)
								return (
									date.isBefore(moment(selectedContract?.startDate).startOf("day")) ||
									date.weekday() !== 0
								)
							else return false || date.weekday() !== 0
						}}
						onChange={values => setDateRange(values)}
					/>
				</div>
				<div className="flex justify-end space-x-1">
					<AntdButton onClick={() => modalHandler(false)}>Cancel</AntdButton>
					<AntdButton loading={isRotaLoading} disabled={!dateRange} onClick={() => submitRotaLoad(dateRange)}>
						Create
					</AntdButton>
				</div>
			</div>
		</Modal>
	)
}

export default RotaLoadModal
