import React, { useEffect, useState } from "react"
import { AntdButton, DateField, FormInputAntd, FormSelectAntd } from "components"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { servicesInitialValues, servicesValidationSchema } from "../../Schema"
import Table from "components/Table2"
import { useAppDispatch } from "hooks"
import { getServices, saveService, selectSavingService, selectServiceList } from "store/Employee/service.slice"
import { CreateServiceInterface } from "@type/employees.types"
import useAppSelector from "./../../hooks/useAppSelector"
import { selectPayGroupData } from "store/PayGroups/payGroup.slice"
import { Utils } from "config"
import { GLOBAL_DATE_FORMAT, Ongoing } from "config/constants"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { selectSelectedEmployee } from "store/globalState.slice"
import { momentZ } from "config/moment"
const { convertIOSDateToHTMLFormat } = Utils
interface DetailFormHandler extends CreateServiceInterface {}

const ServiceDetail: React.FC = () => {
	const dispatch = useAppDispatch()
	const serviceList = useAppSelector(selectServiceList())
	const payGroupList = useAppSelector(selectPayGroupData())
	const isSaving = useAppSelector(selectSavingService())

	const selected = useAppSelector(selectSelectedEmployee)

	const [updateServiceData, setUpdateServiceData] = useState<boolean>(false)

	const methods = useForm<DetailFormHandler>({
		resolver: yupResolver(servicesValidationSchema),
	})

	const {
		handleSubmit,
		formState: { errors, isDirty },
		reset,
		setValue,
		watch,
	} = methods

	useEffect(() => {
		selected && dispatch(getServices(selected))
		reset(servicesInitialValues)
	}, [selected])

	useEffect(() => {
		if (!isSaving) {
			const onGoingService = serviceList.find(item => !item.finishDate)
			reset(onGoingService ?? servicesInitialValues)
			setUpdateServiceData(!!onGoingService)
		}
	}, [serviceList])

	const handleServiceDetailHandler = (bankData: DetailFormHandler) => {
		setUpdateServiceData(true)
		reset({ ...bankData })
	}

	useEffect(() => {
		if (payGroupList.length) setValue("payGroup", payGroupList[0]._id)
	}, [payGroupList?.length])

	const onSubmit: SubmitHandler<DetailFormHandler> = data => {
		dispatch(
			saveService({
				...data,
				employee: selected,
				...(data.finishDate ? { finishDate: momentZ(data.finishDate).endOf("d").toISOString() } : {}),
			}),
		)
	}
	return (
		<>
			<div className="grid grid-cols-12 space-x-5">
				<div className="col-span-6">
					<FormProvider {...methods}>
						<form id={"formId"} onSubmit={handleSubmit(onSubmit)}>
							<div className="space-y-5">
								<div className="grid grid-cols-2 gap-y-2 gap-x-4">
									<DateField
										label={"Start Date"}
										setValue={setValue}
										watch={watch}
										name={"startDate"}
									/>
									<DateField
										label={"Finish Date"}
										setValue={setValue}
										watch={watch}
										errors={errors}
										name={"finishDate"}
									/>

									<FormInputAntd type="number" min={0} label="Min Hours Per Week" name="minHours" />
									<FormInputAntd type="number" min={0} label="Max Hours Per Week" name="maxHours" />
									<FormInputAntd type="text" label="Staff Number" name="staffNumber" />
									<FormInputAntd type="text" label="Staff Number" name="staffNumber" />
									<FormInputAntd type="number" min={0} label="Averaged Over" name="averagedOver" />
									<FormInputAntd type="number" min={0} label="Min Events Per Week" name="minEvents" />
									<FormInputAntd type="number" min={0} label="Max Events Per Week" name="maxEvents" />

									<FormSelectAntd
										label="Pay Group"
										name="payGroup"
										disabled={!payGroupList.length}
										options={payGroupList?.map((payGroup: any) => ({
											label: payGroup.name,
											value: payGroup._id,
										}))}
									/>
									<FormInputAntd type="number" min={0} label="Pro Rata" name="proRata" />
									<FormInputAntd
										type="number"
										min={0}
										label="Additional Contact Hours"
										name="additionalContractHours"
									/>
								</div>
								<div className="flex space-x-2">
									<AntdButton
										onClick={e => {
											e.preventDefault()
											reset(servicesInitialValues)

											setUpdateServiceData(false)
										}}
										htmlType="button"
									>
										Clear
									</AntdButton>
									<AntdButton
										loading={isSaving}
										disabled={!isDirty || isSaving}
										className=" flex items-center justify-center "
										htmlType="submit"
										form="formId"
									>
										{updateServiceData ? "Update" : "Save"}
									</AntdButton>
								</div>
							</div>
						</form>
					</FormProvider>
				</div>
				<div className="col-span-6 mt-1.25">
					<Table
						rows={serviceList ? serviceList : []}
						columns={[
							{
								label: "Sr. No",
								key: "serialNumber",
								render: data => <div>{serviceList?.indexOf(data.row) + 1}</div>,
							},
							{
								label: "Start Date",
								key: "startDate",
								render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
							},
							{
								label: "Finish Date",
								key: "finishDate",
								render: ({ row, key }) =>
									row[key] ? convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT) : Ongoing,
							},
							{
								label: "History",
								key: "_id",
								render: ({ row }) => (
									<AuditTrail id={row["_id"]} name={Entities.EMPLOYEE_SERVICES} showLabel={false} />
								),
							},
							{
								label: "Actions",
								key: "action",
								render: ({ row }) => {
									return (
										<AntdButton onClick={() => handleServiceDetailHandler(row)}>Update</AntdButton>
									)
								},
							},
						]}
					/>
				</div>
			</div>
		</>
	)
}

export default ServiceDetail
