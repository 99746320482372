import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector, useHeightResize } from "hooks"
import { AlertModal, AntdButton, ConfirmationModal, EmployeeSidebar } from "components"
import { selectSelectedEmployeeData } from "store/Employee/detail.slice"
import EmployeeTable, { TableCell } from "./EmployeeTable"
import { AddDateIfFinishTimeIsGreater, convertDateTimeStringToIsoUtc } from "config/utils"
import {
	acceptSchedule,
	clearInactiveScheduleByEmployee,
	clearSchedule,
	deletingSchedule,
	getScheduleByEmployeeAndDateRange,
	saveInactiveSchedule,
	saveScheduleByEmployee,
	selectScheduleDelete,
} from "store/Schedules/schedules.slice"
import moment from "moment"
import CreateEmployeeScheduleModal, { CreateScheduleByEmployeeFormInterface } from "./CreateEmployeeScheduleModal"
import { selectCurrentUser } from "store/auth.slice"
import { DATE_FORMATS, GLOBAL_PAYLOAD_DATE_FORMAT, ScheduleTypesEnum } from "config/constants"
import {
	setSelectedEmployeeBranch,
	selectSelectedEmployee,
	setSelectedSupplier,
	setSelectedLocation,
	setSelectedLocationBranch,
} from "store/globalState.slice"
import AllocateEmployeeModal from "./AllocateEmployeeModal"
import UpdateEmployeeScheduleModal from "./UpdateEmployeeScheduleModal"
import ScheduleCrudBar from "../ScheduleCrudBar"
import ScheduleAuditTrail from "../Location/ScheduleAuditTrailModal"
import CreateRecurringEmployeeScheduleModal from "./CreateRecurringEmployeeScheduleModal"
import { ScheduleStatus } from "../Constants/schedulesConstants"
import { DatePicker } from "antd"
import { ConfigProvider, Divider, Statistic } from "antd-v5"

interface props {
	parentDate: moment.Moment
	setParentDate: Dispatch<SetStateAction<moment.Moment>>
}

const Employee: React.FC<props> = ({ parentDate, setParentDate }) => {
	const dispatch = useAppDispatch()
	useHeightResize({ element: "employee-schedule-table" })
	const selected = useAppSelector(selectSelectedEmployee)
	const [selectedCell, setSelectedCell] = useState<TableCell[]>([])
	const [date, setDate] = useState<string>(moment(parentDate).format(GLOBAL_PAYLOAD_DATE_FORMAT))
	const [createOpen, setCreateOpen] = useState<boolean>(false)
	const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false)
	const [alertOpen, setAlertOpen] = useState<boolean>(false)
	const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
	const [allocationModalOpen, setAllocationModalOpen] = useState<boolean>(false)
	const [openRecurringModal, setOpenRecurringModal] = useState<boolean>(false)
	const [showAuditScheduleModal, setShowAuditScheduleModal] = useState<boolean>(false)
	const [isRecurring, setIsRecurring] = useState<boolean>(false)
	const [createScheduleModalData, setCreateScheduleModalData] =
		useState<CreateScheduleByEmployeeFormInterface | null>(null)
	const [showDeleted, setShowDeleted] = useState<boolean>(false)

	const user = useAppSelector(selectCurrentUser())
	const selectedEmployee = useAppSelector(selectSelectedEmployeeData)

	const isScheduleDeleting = useAppSelector(selectScheduleDelete())

	useEffect(() => {
		if (date) setParentDate(moment(date, GLOBAL_PAYLOAD_DATE_FORMAT))
	}, [date])

	useEffect(() => {
		if (selectedEmployee?.branch) dispatch(setSelectedEmployeeBranch(selectedEmployee?.branch))
		else if (selectedEmployee?.supplier) dispatch(setSelectedSupplier(selectedEmployee?.supplier))
	}, [selectedEmployee])

	useEffect(() => {
		setSelectedCell([])
	}, [selectedEmployee, date])

	useEffect(() => {
		if (selected && date) {
			dispatch(
				getScheduleByEmployeeAndDateRange({
					startTime: moment(date).startOf("month").format("YYYY-MM-DD hh:mm"),
					finishTime: moment(date).endOf("month").format("YYYY-MM-DD hh:mm"),
					employee: selected,
				}),
			)
			dispatch(clearInactiveScheduleByEmployee())
		} else if (!selected) {
			dispatch(clearSchedule())
		}
	}, [date, selected])

	useEffect(() => {
		if (selectedCell?.length) {
			if (selectedCell[0]?.date) setParentDate(moment(selectedCell[0]?.date, DATE_FORMATS.DD_MM_YYYY))
			if (selectedCell[0]?.location) {
				dispatch(setSelectedLocation(selectedCell[0]?.location?._id))
				if (selectedCell[0]?.location?.branch) {
					dispatch(setSelectedLocationBranch(selectedCell[0]?.location?.branch as string))
				}
			}
		}
	}, [selectedCell])

	const handleCreate = () => {
		if ((selectedCell?.length as number) === 1) {
			setCreateOpen(!createOpen)
		} else {
			setAlertOpen(!alertOpen)
		}
	}

	const handleDelete = (notes: string) => {
		if ((selectedCell?.length as number) >= 1) {
			const ids: string[] = []
			selectedCell?.map(cell => {
				if (cell?._id) return ids.push(cell?._id)
			})

			if (ids.length) dispatch(deletingSchedule({ ids, notes }, () => setSelectedCell([])))
		} else {
			setAlertOpen(!alertOpen)
		}
	}

	const cellSelectionHandler = (e: any) => {
		if (e) setSelectedCell([e])
		else setSelectedCell(() => [])
	}

	const onAcceptSchedule = () => {
		const payload = selectedCell?.map(schedule => schedule?._id || "") || []
		dispatch(acceptSchedule(payload, () => setSelectedCell([])))
	}

	const disableButtons = useMemo(() => {
		return selectedCell?.some(cell => !cell._id)
	}, [selectedCell])

	const handlePrev = () => {
		setDate(moment(date).add(-1, "months").format(GLOBAL_PAYLOAD_DATE_FORMAT))
		setSelectedCell([])
	}
	const handleNext = () => {
		setDate(moment(date).add(1, "months").format(GLOBAL_PAYLOAD_DATE_FORMAT))
		setSelectedCell([])
	}

	const isInactiveScheduleSelected = useMemo(
		() => !!selectedCell.find(cell => cell.status === ScheduleStatus.INACTIVE) || false,
		[selectedCell],
	)

	const onRecurringSchedules = () => {
		if (isInactiveScheduleSelected) {
			setOpenRecurringModal(true)
		} else {
			setCreateOpen(true)
			setIsRecurring(true)
		}
	}

	const handleClearCells = () => {
		setCreateScheduleModalData(null)
		setSelectedCell([])
	}

	const resetTable = () => {
		setCreateScheduleModalData(null)
		setSelectedCell([])
	}

	const onSubmit = (data: CreateScheduleByEmployeeFormInterface) => {
		const { startTime, finishTime, type, notes, bypassSiaVerification, bypassMaxConsecutiveWorkdaysVerification } =
			data

		const starTimeMoment = convertDateTimeStringToIsoUtc(`${selectedCell?.[0].date} ${startTime}`)

		let finishTimeMoment = convertDateTimeStringToIsoUtc(`${selectedCell?.[0].date} ${finishTime}`)

		finishTimeMoment = AddDateIfFinishTimeIsGreater(starTimeMoment, finishTimeMoment)

		if (isRecurring) {
			setCreateScheduleModalData({
				...data,
				date: selectedCell?.[0].date,
				startTime: starTimeMoment,
				finishTime: finishTimeMoment,
				employee: selected,
			})
			setOpenRecurringModal(true)
			setCreateOpen(false)
			return
		}

		const payload = {
			location: data.location,
			employee: selected,
			user: user?._id,
			posts: [{ postId: data.post, dates: [{ startTime: starTimeMoment, finishTime: finishTimeMoment }] }],
			bypassSiaVerification,
			bypassMaxConsecutiveWorkdaysVerification,
			type,
			notes,
		}
		dispatch(
			saveScheduleByEmployee(payload, () => {
				setSelectedCell([])
				setCreateOpen(false)
			}),
		)
	}

	const onCreateScheduleFromInactiveSchedule = () => {
		const payload = selectedCell.map(data => {
			return {
				...(data?.employee && { employee: data?.employee }),
				location: data.location?._id || "",
				posts: [
					{
						postId: data?.post || "",
						dates: [
							{
								startTime: convertDateTimeStringToIsoUtc(
									data?.startTime || "",
									DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1,
								),
								finishTime: convertDateTimeStringToIsoUtc(
									data?.finishTime || "",
									DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1,
								),
							},
						],
					},
				],
				type: (data?.type as ScheduleTypesEnum) || "",
				user: user?._id,
				createdFromRecur: data?._id,
			}
		})

		dispatch(saveInactiveSchedule(payload, () => resetTable()))
	}

	return (
		<>
			<EmployeeSidebar />
			<ScheduleCrudBar
				onCreateSchedules={handleCreate}
				onRecurringSchedules={onRecurringSchedules}
				onAcceptSchedule={onAcceptSchedule}
				onUpdateSchedules={() => setUpdateModalOpen(true)}
				onAllocateSchedule={() => setAllocationModalOpen(true)}
				onCreateInActiveSchedule={onCreateScheduleFromInactiveSchedule}
				onIncidentReport={() => ""}
				onDeleteSchedule={() => setDeleteOpen(true)}
				onAuditSchedule={() => setShowAuditScheduleModal(true)}
				showDeletedSchedules={val => setShowDeleted(val)}
				disableCreate={!selectedCell?.length}
				disableAccept={disableButtons || isInactiveScheduleSelected}
				disableUpdate={disableButtons || isInactiveScheduleSelected}
				disableAllocate={disableButtons || isInactiveScheduleSelected}
				disableIncident={true}
				disableAuto={!isInactiveScheduleSelected}
				disableAudit={selectedCell?.[0]?._id || ""}
				disableDelete={disableButtons}
			/>
			<div className="flex flex-col gap-2.5 py-2.5">
				<ConfigProvider
					theme={{
						components: {
							Divider: {
								marginLG: 0,
							},
						},
					}}
				>
					<Divider />
					<div className="flex items-center justify-between">
						<div className="grow">
							<Statistic
								key="Employee"
								title="Employee"
								value={
									selectedEmployee
										? `${selectedEmployee.firstName} ${selectedEmployee.lastName}`
										: "No employee"
								}
							/>
						</div>
						<div className="flex flex-row space-x-1">
							<DatePicker
								value={moment(date, GLOBAL_PAYLOAD_DATE_FORMAT)}
								format={GLOBAL_PAYLOAD_DATE_FORMAT}
								placeholder={DATE_FORMATS.DD_MM_YYYY}
								onChange={date => setDate(moment(date).format(GLOBAL_PAYLOAD_DATE_FORMAT))}
							/>

							<AntdButton onClick={handlePrev}>Prev</AntdButton>
							<AntdButton onClick={handleNext}>Next</AntdButton>
							<AntdButton onClick={handleClearCells}>Clear</AntdButton>
						</div>
					</div>
					<Divider />
				</ConfigProvider>

				<div id="employee-schedule-table" className="schedule-scrollbar overflow-y-auto">
					<EmployeeTable
						showDeleted={showDeleted}
						date={date}
						selectedCell={selectedCell}
						cellSelectionHandler={cellSelectionHandler}
					/>
				</div>
			</div>
			{createOpen && (
				<CreateEmployeeScheduleModal
					onSubmit={onSubmit}
					open={createOpen}
					modalHandler={close => {
						setCreateOpen(close)
						setIsRecurring(false)
					}}
					isRecurringSchedule={isRecurring}
				/>
			)}
			{openRecurringModal && (
				<CreateRecurringEmployeeScheduleModal
					open={openRecurringModal}
					modalHandler={val => {
						setOpenRecurringModal(val)
						setCreateScheduleModalData(null)
						setIsRecurring(false)
					}}
					createScheduleModalData={createScheduleModalData}
					selectedCell={selectedCell}
					resetTable={resetTable}
				/>
			)}
			{alertOpen && (
				<AlertModal
					open={alertOpen}
					message="Please select one Cell to Create schedule for Employee"
					modalHandler={close => {
						setAlertOpen(close)
					}}
				/>
			)}
			{deleteOpen && (
				<ConfirmationModal
					confirmationAction={false}
					open={deleteOpen}
					loader={isScheduleDeleting}
					confirmationText={"delete"}
					modalHandler={close => {
						setDeleteOpen(close)
						setSelectedCell([])
					}}
					onConfirmation={notes => {
						handleDelete(notes)
						setDeleteOpen(false)
					}}
				/>
			)}
			{allocationModalOpen && (
				<AllocateEmployeeModal
					open={allocationModalOpen}
					setSelectedCell={setSelectedCell}
					modalHandler={setAllocationModalOpen}
					selectedSchedules={selectedCell || []}
				/>
			)}
			{updateModalOpen && (
				<UpdateEmployeeScheduleModal
					open={updateModalOpen}
					setSelectedCell={setSelectedCell}
					modalHandler={setUpdateModalOpen}
					selectedSchedules={selectedCell || []}
				/>
			)}
			<ScheduleAuditTrail
				modalHandler={setShowAuditScheduleModal}
				open={showAuditScheduleModal}
				scheduleId={selectedCell[0]?._id || ""}
			/>
		</>
	)
}

export default Employee
